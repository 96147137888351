import './Reward.css'
import useRefresh from '../../hooks/useRefresh';
import {Button } from "@material-ui/core";
import { ethers } from "ethers";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import { contractAddress, contractAbi } from "../../contracts";
import React, {useEffect} from 'react';

let connectedAddress = null;
let signer = null;

export default function RewardToken(wallet) {
  signer = useSigner();
  const [tokenBalance, setTokenBalance] = React.useState('0');
  const [tokenBalanceFormatted, setTokenBalanceFormatted] = React.useState('0');
  const [totalRewardAmount, setTotalRewardAmount] = React.useState('0');
  const [claimableAmount, setClaimableAmount] = React.useState('0');
  // const [minimumOutput, setMinimumOutput] = React.useState('');
  const {fastRefresh} = useRefresh();
  let contract = new ethers.Contract(contractAddress, JSON.parse(contractAbi), signer);

  const handleClaimETH = async() => {
    try {
      const transaction = await contract.claimEth();

      await transaction.wait().then((result) => {
        console.log("Claim successful!");
      });
    } catch(ex) {
      console.log(ex);
    }
  }

  const handleClaimCBETH = async() => {
    try {
      const transaction = await contract.claimCBETH(0);

      await transaction.wait().then((result) => {
        console.log("Claim successful!");
      });
    } catch(ex) {
      console.log(ex);
    }
  }

  const handleClaimCBBTC= async() => {
    try {
      const transaction = await contract.claimCBBTC(0);

      await transaction.wait().then((result) => {
        console.log("Claim successful!");
      });
    } catch(ex) {
      console.log(ex);
    }
  }

  const handleCompound= async() => {
    try {
      const transaction = await contract.compound(0);

      await transaction.wait().then((result) => {
        console.log("Claim successful!");
      });
    } catch(ex) {
      console.log(ex);
    }
  }

  useEffect(()=>{    
    const update = async () => {
      if(signer){
          try {
            const v2HoldingData = await contract.balanceOf(connectedAddress);
            
            setTokenBalance(parseFloat(ethers.utils.formatEther(v2HoldingData)).toFixed(0));
            setTokenBalanceFormatted(parseFloat(ethers.utils.formatEther(v2HoldingData)).toLocaleString());

            const claimAmount = await contract.withdrawableDividendOf(connectedAddress);
            let formattedClaimableAmount = ethers.utils.formatEther(claimAmount);
            setClaimableAmount(parseFloat(formattedClaimableAmount).toFixed(8));

            const claimedAmount = await contract.getAccountDividendsInfo(connectedAddress);
            console.log(claimedAmount);
            let formattedClaimedAmount = ethers.utils.formatEther(claimedAmount[2]);
            setTotalRewardAmount(parseFloat(formattedClaimedAmount).toFixed(8));
            
          } catch (err) {
              console.log(err); 
          }
      }
    }
    update();
  },[fastRefresh, signer, contract]);

  signer = useSigner();
  connectedAddress = useAddress();

  

  return (
    <div className='overwrap'>
      <div className='left-content'>
        <div className='title-text'>   
          hold $sphere to start earning.
        </div>
        <div className='heading-text'>
          sphere rewards<br />dapp.
        </div>
        <div className='description-text'>
          by simply holding $sphere, you unlock access to a constant <u>stream of rewards</u>. 
          choose to claim your rewards as cbbtc, cbeth, sphere, or eth directly through the dapp. 
          the more $sphere tokens you hold, the greater your share of the rewards, as earnings 
          are directly proportional to your holdings.
        </div>
        
        <div className='tokens-held'> 
          <div className='tokens-label'>tokens held:</div>
          <div className='tokens-value'>{tokenBalanceFormatted} $sphere.</div>
        </div>
      </div>

      <div className='right-content'>
        <div className='button-container'>
          {[
            {
              handler: handleClaimCBBTC,
              bgColor: 'rgb(231,117,34,0.69)',
              text: 'claim as cbBTC.',
              img: "https://static.wixstatic.com/media/d4f8f4_d49ca036eff9461b955387b253dc21b2~mv2.png",
              alt: "CbBTC"
            },
            {
              handler: handleClaimCBETH,
              bgColor: 'rgb(134,122,182,0.69)',
              text: 'claim as cbETH.',
              img: "https://static.wixstatic.com/media/d4f8f4_54fce7986f874170a2e42823b9b7aea2~mv2.png",
              alt: "CbETH"
            },
            {
              handler: handleCompound,
              bgColor: 'rgb(35,107,210,0.69)',
              text: 'claim as sphere.',
              img: "https://static.wixstatic.com/media/d4f8f4_f99b0158611f4139a73448c9ef6851b4~mv2.png",
              alt: "sphere"
            },
            {
              handler: handleClaimETH,
              bgColor: 'rgb(140,140,140,0.69)',
              text: 'claim as ETH.',
              img: "https://static.wixstatic.com/media/d4f8f4_d0cd8ac8e58246b7a56273db6b8a4b5a~mv2.png",
              alt: "ETH"
            },
          ].map((btn, index) => (
            <div 
              key={index}
              className='btn btn-primary' 
              onClick={btn.handler} 
              style={{ backgroundColor: btn.bgColor }}
            >
              <span>{btn.text} →</span>
              <img alt={btn.alt} src={btn.img} height='40px' width='40px' />
            </div>
          ))}
        </div>

        <div className='total-value'> 
          total value in eth (claimed+unclaimed):
          <div className='tokens-value'>
            {totalRewardAmount} eth
          </div>
        </div>
      </div>
    </div>
  )
}

  
