import './Header.css'
import {Button } from "@material-ui/core";
import { ConnectWallet } from "@thirdweb-dev/react";
import { useSigner } from "@thirdweb-dev/react";
import RewardToken from "../RewardToken";
export default function Header(props) {
    var signer = useSigner();

    return(
        <div style={{ 
            backgroundImage: `url('https://static.wixstatic.com/media/d4f8f4_f8a1a468990e423f9ae347ddbaef0651~mv2.png')`, 
            backgroundColor: 'black', 
            backgroundSize: 'cover',
            backgroundPosition: '50% 30%',
            backgroundAttachment: 'fixed',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <header>
                <div className="container">
                    <nav>
                        <div className="brand">
                            <img alt='' src='https://static.wixstatic.com/media/d4f8f4_7df8e849132c4d67a7b72a695a2eda0d%7Emv2.png/v1/fill/w_192%2Ch_192%2Clg_1%2Cusm_0.66_1.00_0.01/d4f8f4_7df8e849132c4d67a7b72a695a2eda0d%7Emv2.png' />
                        </div>
                        <div className='nav-cta'>
                            <ConnectWallet 
                                style={{ 
                                    width: "auto",
                                    height: "44px",
                                    padding: "0 24px",
                                    fontSize: "16px"
                                }} 
                                switchToActiveChain={true} 
                                className="btn btn-primary" 
                                theme="dark" 
                                dropdownPosition={{ 
                                    side: 'bottom', 
                                    align: 'center'
                                }} 
                            />
                        </div>
                    </nav>
                </div>
            </header>
            <main>
                <div className="container content-wrapper">
                    {signer != null ? (
                        <div className='text-center'>
                            <RewardToken/>
                        </div>
                    ) : (
                        <div className='hero-content'>
                            <div className="subtitle">   
                                hold $sphere to start earning.
                            </div>
                            <h1 className="title">
                                sphere rewards dapp.
                            </h1>
                            <div className="description">
                                by simply holding $sphere, you unlock access to a constant <u>stream of rewards</u>. choose to claim your rewards as cbBTC, cbETH, sphere, or ETH directly through the dapp. the more $sphere tokens you hold, the greater your share of the rewards, as earnings are directly proportional to your holdings.
                            </div>
                            <div className="cta">
                                <h3>Connect Wallet to Claim</h3>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            
            <footer>
                <div className="container">
                    <p style={{ 
                        margin: 0,
                        color: 'rgba(255,255,255,0.7)',
                        fontSize: '16px'
                    }}>&copy; 2024 spherebase.io - All rights reserved</p>
                </div>
            </footer>
        </div>
    );
}
